<template>


   

    <div style="width:100%;color:#606266">

        <div class="inputStyle">
            <el-select type="text" class="inputSelect" v-model="project_codeStr" @change="fetchData">



                <el-option
                v-for="item in project_codes"
                :key="item"
                :label="item"
                :value="item"
                >
                </el-option>

            </el-select>
        </div>
      
        <div style="padding:20px">
            <el-table :data="roomList">
                <el-table-column label="项目" prop="event" width="200"></el-table-column>
                <el-table-column label="准备开始时间" prop="prep_start_time"></el-table-column>
                <el-table-column label="准备结束时间" prop="prep_end_time"></el-table-column>
                <el-table-column label="正式演示时间" prop="prez_start_time"></el-table-column>
                <el-table-column label="房间号" prop="room" width="100"></el-table-column>
                <el-table-column label="腾讯会议ID" prop="meeting_room_code"></el-table-column>
                <el-table-column label="类型" prop="team_type" width="100"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" @click="toView(scope.row._id.$id,scope.row.room,'',scope.row.team_type,scope.row.event)">进入准备室</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import {getUserId,getProjectCode,getProjectBU} from '../../utils/store'
import {formatDate} from '../../utils/date'
import {getPresentationRoomsByApplication,checkPresentationRoomOpen,getUserOpenProjectCode} from '../../api/index'
import "../../assets/common/font.css";

export default {
    data(){
        return{
            roomList:[],
            open_status:[],
            showSocialTip:true,
            showSocialTipTitle:'收起 Fold',
            project_codes:[],
            project_codeStr : this.$t('m.messageProjectCode')

        }
    },
    mounted(){
        this.getUserOpenProjectCode()
    },
    methods:{

        getUserOpenProjectCode(){


            var foreign = 'false'

            if (this.showChinese == 'english'){
            foreign = 'true'
            }

            getUserOpenProjectCode(getUserId(),foreign,getProjectBU()).then((res) => {
            // console.log(1111,res)
                this.project_codes = res.data
            });
        },

        fetchData(){

            if (this.project_codeStr == this.$t('m.messageProjectCode')){

                this.$message.error(this.$t('m.messageProjectCode'));
                return
            }

            let student_id = getUserId()
            getPresentationRoomsByApplication(student_id,this.project_codeStr).then(res=>{
                console.log(res)
                if(res.data.code==0){
                    this.roomList=res.data.data
                }
            })
        },
        formatDate(time){
            let date = new Date(time*1000)
            return formatDate(date,'Y-MM-dd hh:mm:ss')
        },
        toView(room_id,roomId,application_id,type,event_name){
            this.checkPrezRoomOpen(room_id,roomId,application_id,type,event_name)

            // console.log(123123,event_name)
            
            // this.$router.push({
            //     path:'/discussing',
            //     query:{
            //         roomId:roomId,
            //         application_id:application_id,
            //         event_name:event_name
            //     }
            // })


            
        },
        checkPrezRoomOpen(room_id,roomId,application_id,type,event_name){


            
            checkPresentationRoomOpen(room_id).then(res=>{
                


                var open_data  = res.data

            // console.log(open_data)

                if (open_data.code == 0) {


                    // if(type=='Preparation'){
                        this.$router.push({
                            path:'/discussing',
                            query:{
                                roomId:roomId,
                                application_id:application_id,
                                event_name:event_name
                            }
                        })
                    // }else{
                    //     this.$router.push({
                    //         path:'/student/meeting',
                    //         query:{
                    //             roomId:roomId,
                    //             application_id:application_id
                    //         }
                    //     })
                    // }
                }else{

                    //TODO - 提示错误信息
                    this.$message(open_data.msg)
                }
                

            })
            
        },
        echo(){

            console.log('echo')
        },
        showSocialTipClick(){
          if(this.showSocialTip == true){
             this.showSocialTip = false;
             this.showSocialTipTitle = '展开 Unfold';
          }else{
             this.showSocialTip = true;
             this.showSocialTipTitle = '收起 Fold';
          }
        }
    }
}
</script>

<style>


.inputSelect {
    width: 230px;
    height: 40px;
    margin: 0 auto;
    

  }
.inputStyle {
  width: 100%;
  height: 50px;
  margin-left: 20px;
  margin-top:10px;  
}

</style>
